import React from "react";
import aboutImage1 from "../images/about-img.webp";
import aboutImage2 from "../images/about-img2.webp";
import sectionIcon from "../images/section-icon.png";
const AboutArea = () => {
  return (
    <section className="about-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="about-large-img">
              <img src={aboutImage1} alt="" />
            </div>
          </div>
          <div className="col-lg-5">
            <div className="about-semi-img">
              <img src={aboutImage2} alt="" />
            </div>
          </div>
          <div className="col-lg-7">
            <div className="about-heading">
              <div className="section-heading">
                <div className="section-icon">
                  <img src={sectionIcon} alt="section-icon" />
                </div>
                <h2 className="section__title">Discover About Pdjjks</h2>
                <p className="section__meta">learn about non profit agency</p>
                <p className="section__desc">
                  We are an Indian NGO dedicated to improving the lives of
                  people in our community through a variety of initiatives. 
                  <p>Our focus is on blood donation, tree plantation, the Fit India
                  movement, and sanitation works. Our blood donation program
                  aims to meet the demand for blood and save lives. We organize
                  blood donation camps and work with local hospitals to ensure
                  that those in need have access to this vital resource.</p> 
                  <p>Our tree plantation program is focused on enhancing environmental
                  sustainability by planting and nurturing trees. We believe
                  that by increasing green spaces in our community, we can
                  improve air quality and provide a healthier environment for
                  all.</p> 
                  <p>The Fit India movement is a national initiative aimed at
                  promoting physical fitness and healthy living. We support this
                  movement by organizing fitness events and providing resources
                  to help people lead active and healthy lives.</p> 
                  <p>Finally, our sanitation program is focused on improving hygiene and
                  sanitation in underprivileged communities. We work to provide
                  access to clean water and sanitation facilities, as well as
                  educating people about the importance of good hygiene
                  practices.</p> 
                  <p>Together, these programs form the core of our
                  mission to improve the lives of people in our community. We
                  are committed to making a positive impact and welcome your
                  support in achieving our goals.</p>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutArea;
